import { toSentenceCase } from '@utils/toSentenceCase';
import React, { type ReactNode } from 'react';
import Styles from './styles.scss';

interface PillProps {
	label: string | ReactNode;
	onClick: () => void;
	customStyles?: string;
	selected: boolean;
	customSelectedStyles?: string;
	showMapView?: boolean;
}

const Pill = ({
	label,
	onClick,
	customStyles,
	selected,
	customSelectedStyles,
	showMapView,
}: PillProps) => {
	const pillClasses = `${
		showMapView ? Styles.pillMapContainer : Styles.pillContainer
	} ${customStyles} ${selected ? customSelectedStyles || Styles.selected : ''}`;

	const processedLabel =
		typeof label === 'string' ? toSentenceCase(label) : label;

	return (
		<button type="button" className={pillClasses} onClick={onClick}>
			{processedLabel}
		</button>
	);
};

export default Pill;
