import type { Track } from '@typings/analytics';
import savedVendors from '@utils/savedVendors';
import type { SnackbarRef } from '@xo-union/tk-component-alerts';
import { useCallback } from 'react';
import React from 'react';
import UnsavedMessage from '../components/unsaved-message';

export const useDestroyFavorite = (
	sessionToken: string | null,
	weddingId: string | undefined,
	savedVendorId: string | undefined | null,
	setSavedVendorId: (savedVendorId?: string | null) => void,
	emptyFavoriteHeart: () => void,
	alertRef: React.RefObject<SnackbarRef>,
	track: Track,
	vendor: Vendor.Decorated,
	trackAddToFavorites: () => void,
) => {
	return useCallback(() => {
		return savedVendors
			.destroyByObjectId({
				sessionToken,
				weddingId,
				savedVendorId,
			})
			.then((response) => {
				if (!response.ok) {
					alertRef.current?.showAndDismiss({
						data: 'Removing from Your Vendors Failed. Please Retry',
						dismissTimeout: 5000,
					});
					return;
				}

				const unsavedMessage = <UnsavedMessage vendor={vendor} track={track} />;
				alertRef.current?.showAndDismiss({
					data: unsavedMessage,
					dismissTimeout: 5000,
				});

				emptyFavoriteHeart();
				trackAddToFavorites();
				setSavedVendorId(null);
			});
	}, [
		sessionToken,
		emptyFavoriteHeart,
		setSavedVendorId,
		weddingId,
		savedVendorId,
		vendor,
		alertRef,
		track,
		trackAddToFavorites,
	]);
};
