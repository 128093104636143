import { useCallback } from 'react';
import type { Track } from 'types/analytics';
import AnalyticsConstants from '../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

export const useTrackAddToFavorites = (
	vendor: Vendor.Decorated,
	track: Track,
	memberId: string,
	sourceContent?: string,
	isRemovingFromFavorites = false,
) => {
	return useCallback(() => {
		const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
		const vendorAnalytics = new VendorAnalytics(vendor);

		track({
			event: !isRemovingFromFavorites
				? AnalyticsConstants.ADD_TO_FAVORITE
				: AnalyticsConstants.REMOVE_FROM_FAVORITE,
			properties: {
				sourcePage: `${paidStatus} storefront`,
				userDecisionArea: 'Vendor Info',
				sourceContent,
				memberId,
				...vendorAnalytics.baseEventProps(),
			},
		});
	}, [vendor, track, memberId, sourceContent, isRemovingFromFavorites]);
};
