import { tkHost } from '@settings';
import React, { type FC } from 'react';
import type { Track } from 'types/analytics';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';
import Styles from '../../styles.scss';

interface Props {
	vendor: Vendor.Decorated;
	track: Track;
}

const trackFavoritesListClick = (vendor: Vendor.Decorated, track: Track) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	track({
		event: AnalyticsConstants.CLICK_THROUGH_TO_VENDOR_LIST,
		properties: {
			sourcePage: AnalyticsConstants.STOREFRONT,
			sourceContent: AnalyticsConstants.UNFAVORITED_VENDOR,
			...vendorAnalytics.baseEventProps(),
		},
	});
};

const UnsavedMessage: FC<Props> = ({ vendor, track }) => {
	return (
		<span role="alert">
			Removed from&nbsp;
			<a
				className={Styles.favLink}
				href={`${tkHost}/wedding-vendor-manager`}
				onClick={() => trackFavoritesListClick(vendor, track)}
				rel="noopener noreferrer nofollow"
				target="_blank"
			>
				Your Vendors
			</a>
		</span>
	);
};

export default UnsavedMessage;
