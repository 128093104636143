import { compose as unionCompose } from '@xo-union/react-css-modules';
import { Snackbar, type SnackbarRef } from '@xo-union/tk-component-alerts';
import { Column } from '@xo-union/tk-component-grid';
import type { OpenModalType } from '@xo-union/tk-component-membership-modal-redux';
import { ThemeProvider } from '@xo-union/tk-component-vendor-card';
import React, { useEffect, useMemo, useRef, useState, type FC } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import useAnalyticsContext from '@hooks/useAnalyticsContext';
import classnames from 'classnames';

import {
	emptyFavoriteHeart as emptyFavoriteHeartAction,
	fillFavoriteHeart as fillFavoriteHeartAction,
	setSavedVendorId as setSavedVendorIdAction,
	setWeddingId as setWeddingIdAction,
} from './actions';

import { CookieStorage } from 'cookie-storage';
import ExpandedHeart from './components/expanded-heart';
import FavoriteHeart from './components/favorite-heart';
import MembershipModal from './components/membership-modal';
import desktopHeartStyles from './desktopStyles.scss';
import { useCreateFavorite } from './hooks/use-create-favorite';
import { useDestroyFavorite } from './hooks/use-destroy-favorite';
import { useGetFavorite } from './hooks/use-get-favorite';
import { useOnToggleHandler } from './hooks/use-on-toggle-handler';
import { useSetup } from './hooks/use-setup';
import { useTrackAddToFavorites } from './hooks/use-track-add-to-favorites';
import mobileHeartStyles from './mobileStyles.scss';
import Styles from './styles.scss';

import type { FCWithChildren } from 'types/react-extended';
import { XO_SESSION_TOKEN } from '../../../../../../../constants/membership';

interface StateProps {
	filled: boolean;
	isMobile: boolean;
	weddingId?: string;
	savedVendorId?: string | null;
	memberId: string;
}

interface DispatchProps {
	emptyFavoriteHeart: () => void;
	fillFavoriteHeart: () => void;
	setWeddingId: (weddingId?: string) => void;
	setSavedVendorId: (savedVendorId?: string | null) => void;
}

interface OwnProps {
	expanded?: boolean;
	membership: Redux.Membership;
	vendor: Vendor.Decorated;
	className?: string;
	transparent?: boolean;
	sourceContent?: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

const Container: FCWithChildren<{
	className?: string;
	transparent: boolean;
}> = ({ className, transparent, children }) => {
	const containerClassname = classnames({
		[className ?? '']: className,
		[Styles.solidBackground]: !transparent,
	});
	return containerClassname.length ? (
		<div className={containerClassname}>{children}</div>
	) : (
		<>{children}</>
	);
};

export const FavoritesButton: FC<Props> = ({
	expanded = false,
	membership,
	memberId,
	vendor,
	emptyFavoriteHeart,
	filled,
	fillFavoriteHeart,
	isMobile,
	weddingId,
	setWeddingId,
	savedVendorId,
	setSavedVendorId,
	className,
	transparent = true,
	sourceContent,
}) => {
	const alertRef = useRef<SnackbarRef>(null);
	const [openModal, setOpenModal] = useState<OpenModalType | null>(null);
	const [pendingGetWedding, setPendingGetWedding] = useState(false);
	const { track } = useAnalyticsContext();

	const cookieStorage = new CookieStorage();
	const sessionToken = cookieStorage.getItem(XO_SESSION_TOKEN);

	const themeAttrs = useMemo(
		() => ({
			theme: isMobile
				? unionCompose(mobileHeartStyles)
				: unionCompose(desktopHeartStyles),
		}),
		[isMobile],
	);

	const trackAddToFavorites = useTrackAddToFavorites(
		vendor,
		track,
		memberId,
		sourceContent,
	);
	const getFavorite = useGetFavorite(
		vendor,
		setSavedVendorId,
		fillFavoriteHeart,
	);
	const createFavorite = useCreateFavorite(
		alertRef,
		sessionToken,
		track,
		vendor,
		fillFavoriteHeart,
		trackAddToFavorites,
		setSavedVendorId,
	);

	const trackRemoveFromFavorites = useTrackAddToFavorites(
		vendor,
		track,
		memberId,
		sourceContent,
		true,
	);

	const destroyFavorite = useDestroyFavorite(
		sessionToken,
		weddingId,
		savedVendorId,
		setSavedVendorId,
		emptyFavoriteHeart,
		alertRef,
		track,
		vendor,
		trackRemoveFromFavorites,
	);
	const onToggleHandler = useOnToggleHandler(
		membership,
		filled,
		weddingId,
		createFavorite,
		destroyFavorite,
		setOpenModal,
	);
	const setup = useSetup(
		membership,
		setPendingGetWedding,
		getFavorite,
		setWeddingId,
		emptyFavoriteHeart,
	);

	useEffect(() => {
		setup();
	}, [setup]);

	return (
		<Container className={className} transparent={transparent}>
			<ThemeProvider {...themeAttrs}>
				{expanded ? (
					<ExpandedHeart filled={filled} onToggleHandler={onToggleHandler} />
				) : (
					<FavoriteHeart filled={filled} onToggleHandler={onToggleHandler} />
				)}
				<MembershipModal
					createFavorite={createFavorite}
					openModal={openModal}
					setOpenModal={setOpenModal}
					pendingGetWedding={pendingGetWedding}
					weddingId={weddingId}
				/>
				<Column xs="8" className={Styles.favAlert}>
					<Snackbar ref={alertRef} />
				</Column>
			</ThemeProvider>
		</Container>
	);
};

export const mapDispatchToProps: DispatchProps = {
	emptyFavoriteHeart: emptyFavoriteHeartAction,
	fillFavoriteHeart: fillFavoriteHeartAction,
	setWeddingId: setWeddingIdAction,
	setSavedVendorId: setSavedVendorIdAction,
};

export function mapStateToProps(state: Redux.State): StateProps {
	return {
		filled: state.favorites.filled,
		weddingId: state.favorites.weddingId,
		savedVendorId: state.favorites.savedVendorId,
		isMobile: state.viewport.isMobile,
		memberId: state.membership.member ? state.membership.member.id : '',
	};
}

const enhanced = compose(
	connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchToProps,
	),
);

export default enhanced(FavoritesButton);
